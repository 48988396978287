import React, { useCallback, useEffect, useState } from 'react';
import { ResolveThunks, connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router';

import { KarteInput, KarteTemplate } from 'src/interfaces/karte';
import { User } from 'src/interfaces/user';
import { RootState } from 'src/reducers';
import { setFlashMessage as setFlashMessageAction } from 'src/actions/common';
import { fetchStaffDetail } from 'src/actions/staffs';
import { PostBookOrderResult, fetchDraftOrder, postBookOrder } from 'src/actions/orders';
import { fetchKarteTemplates, registerKarteTemplate } from 'src/actions/kartes';
import Authenticated from 'src/containers/common/Authenticated';
import ProgressBarTemplate from 'src/components/templates/ProgressBarTemplate';
import UiStackTemplate from 'src/components/common/UiStackTemplate';
import KarteTemplateRegistrationModal from 'src/components/karteTemplates/KarteTemplateRegistrationModal';
import OrderConfirmationPage from 'src/components/orders/OrderConfirmationPage';
import EditKartePage from 'src/components/kartes/EditKartePage';
import OrderPaymentMethodPage, { PaymentMethod } from 'src/components/orders/OrderPaymentMethodPage';
import { FormValues } from 'src/components/kartes/KarteForm';
import useCurrentUser from 'src/hooks/store/useCurrentUser';
import useMutation from 'src/hooks/common/useMutation';
import GATracker from 'src/utils/GATracker';
import { postOrderQuery } from 'src/actions/queries/orderQueries';
import parseToInt from 'src/utils/parseToInt';

export const TotalProgressNumber = 5;

const mapStateToProps = (state: RootState) => ({
  bookOrder: state.orders.bookOrder,
  staff: state.staffs.staff,
  karteTemplates: state.kartes.templates,
  isLoading: state.staffs.isFetchingStaffDetail || state.orders.isFetchingBookOrderDetail,
  isSubmitting: state.orders.isPostingBookOrder,
  isDraftOrderFetched: state.orders.isInitialDraftOrderFetched,
  fetchingError: state.staffs.fetchingStaffDetailError || state.orders.fetchingBookOrderDetailError,
  mutationError: state.orders.postingBookOrderError,
});

const mapDispatchToProps = {
  getDraftOrder: fetchDraftOrder.action,
  getStaffDetail: fetchStaffDetail.action,
  getKarteTemplates: fetchKarteTemplates.action,
  registerTemplate: registerKarteTemplate.action,
  postBookOrder: postBookOrder.action,
  setFlashMessage: setFlashMessageAction,
};

type Props = ReturnType<typeof mapStateToProps> & ResolveThunks<typeof mapDispatchToProps>;

interface PagePositionInfo {
  isPaymentMethodPage: boolean;
  isConfirmationPage: boolean;
}

const getPageInfo = (user: User | null, { isPaymentMethodPage, isConfirmationPage }: PagePositionInfo) => {
  if (!user) {
    return { currentProgressPosition: 1, pageTitle: '申し込みページ' };
  }

  if (isConfirmationPage) {
    return { currentProgressPosition: 4, pageTitle: '決済内容確認' };
  }

  if (isPaymentMethodPage) {
    return { currentProgressPosition: 3, pageTitle: '決済方法選択' };
  }

  return { currentProgressPosition: 2, pageTitle: '選書カルテの記載' };
};

interface SaveBookOrderParams {
  staffId: string;
  karte: KarteInput;
  isDraft: boolean;
  couponCode?: string | null;
}

const NewOrderContainer: React.FC<Props> = ({
  staff,
  bookOrder,
  karteTemplates,
  isLoading,
  isSubmitting,
  isDraftOrderFetched,
  fetchingError,
  mutationError,
  getDraftOrder,
  getStaffDetail,
  getKarteTemplates,
  registerTemplate,
  postBookOrder,
  setFlashMessage,
}) => {
  const [user] = useCurrentUser();
  const [modalIsOpen, toggleModal] = useState(false);
  const [formValues, setFormValues] = useState<FormValues | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<KarteTemplate>();
  const [isPaymentMethodPage, setIsPaymentMethodPage] = useState(false);
  const [isConfirmationPage, setIsConfirmationPage] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null);
  const navigate = useNavigate();

  const { executeMutation: saveDraftOrder } = useMutation<SaveBookOrderParams, PostBookOrderResult>();
  const { executeMutation: autoSaveDraftOrder } = useMutation<SaveBookOrderParams, PostBookOrderResult>();

  const { id: staffId } = useParams<{ id: string }>();

  const { currentProgressPosition, pageTitle } = getPageInfo(user, { isPaymentMethodPage, isConfirmationPage });

  const questionTemplate = bookOrder?.karte.karteQuestionTemplate ?? staff?.karteQuestionTemplate ?? null;

  useEffect(() => {
    if (staffId && staff && staff.id === staffId) {
      return;
    }

    if (staffId) {
      getStaffDetail({ id: staffId });
    }
  }, [getStaffDetail, staffId, staff]);

  useEffect(() => {
    if (staffId && user) {
      getDraftOrder({ staffId, userId: user.id });
    }
  }, [getDraftOrder, staffId, user]);

  useEffect(() => {
    if (user) {
      getKarteTemplates({ userId: user.id });
    }
  }, [getKarteTemplates, user]);

  const onSelectTemplate = (selectedTemplate?: KarteTemplate) => {
    setSelectedTemplate(selectedTemplate);
  };

  const onSubmitKarteForm = async (values: FormValues) => {
    setFormValues(values);
    toggleModal(true);
    GATracker.trackEvent('save_karte');
  };

  const onSave = async (values: FormValues) => {
    const age = parseToInt(values.age) ?? null;

    await saveDraftOrder(postOrderQuery, {
      staffId: staffId!,
      isDraft: true,
      karte: {
        age,
        gender: values.gender,
        answer1: values.answer1!,
        answer2: values.answer2!,
        answer3: values.answer3!,
        answer4: values.answer4!,
        answer5: values.answer5!,
        answer6: values.answer6!,
        answer7: values.answer7!,
        answer8: values.answer8!,
        answer9: values.answer9!,
        answer10: values.answer10!,
        karteQuestionTemplateId: questionTemplate!.id,
      },
    });
    setFlashMessage({ message: '下書きとして保存しました。' });
    GATracker.trackEvent('save_karte_draft');
  };

  const onAutoSave = useCallback(
    async (values: FormValues) => {
      if (questionTemplate?.id) {
        const age = parseToInt(values.age) ?? null;

        await autoSaveDraftOrder(postOrderQuery, {
          staffId: staffId!,
          isDraft: true,
          karte: {
            age,
            gender: values.gender,
            answer1: values.answer1!,
            answer2: values.answer2!,
            answer3: values.answer3!,
            answer4: values.answer4!,
            answer5: values.answer5!,
            answer6: values.answer6!,
            answer7: values.answer7!,
            answer8: values.answer8!,
            answer9: values.answer9!,
            answer10: values.answer10!,
            karteQuestionTemplateId: questionTemplate.id,
          },
        });
      }
    },
    [autoSaveDraftOrder, questionTemplate?.id, staffId],
  );

  const onSubmitTemplate = async (selectedIndex: number | null) => {
    if (formValues && questionTemplate && selectedIndex !== null) {
      await registerTemplate({
        orderNumber: selectedIndex,
        karte: {
          gender: formValues.gender,
          age: formValues.age,
          answer1: formValues.answer1!,
          answer2: formValues.answer2!,
          answer3: formValues.answer3!,
          answer4: formValues.answer4!,
          answer5: formValues.answer5!,
          answer6: formValues.answer6!,
          answer7: formValues.answer7!,
          answer8: formValues.answer8!,
          answer9: formValues.answer9!,
          answer10: formValues.answer10!,
          karteQuestionTemplateId: questionTemplate.id,
        },
      });
      setFlashMessage({ message: 'カルテをマイページに登録しました。' });
    }
    toggleModal(false);
    setIsPaymentMethodPage(true);
  };

  const onSelectPaymentMethod = (method: PaymentMethod) => {
    setIsConfirmationPage(true);
    setPaymentMethod(method);
  };

  const onSubmit = async () => {
    if (formValues && questionTemplate) {
      await postBookOrder({
        staffId: staffId!,
        isDraft: false,
        karte: {
          gender: formValues.gender,
          age: formValues.age,
          answer1: formValues.answer1!,
          answer2: formValues.answer2!,
          answer3: formValues.answer3!,
          answer4: formValues.answer4!,
          answer5: formValues.answer5!,
          answer6: formValues.answer6!,
          answer7: formValues.answer7!,
          answer8: formValues.answer8!,
          answer9: formValues.answer9!,
          answer10: formValues.answer10!,
          karteQuestionTemplateId: questionTemplate.id,
        },
        couponCode: paymentMethod && paymentMethod.paymentMethodType === 'coupon' ? paymentMethod.couponCode : null,
      });
    }
    setFlashMessage({ message: '注文を完了しました。' });
    navigate(`/staffs/${staffId}/orders/thanks`);
    GATracker.trackEvent('purchase');
  };

  const onBackToKartePage = () => setIsPaymentMethodPage(false);
  const onBackToPaymentMethodPage = () => setIsConfirmationPage(false);

  return (
    <ProgressBarTemplate title={pageTitle} position={currentProgressPosition} total={TotalProgressNumber}>
      <Authenticated>
        <UiStackTemplate isLoading={isLoading} error={fetchingError}>
          {staff && user && questionTemplate && isDraftOrderFetched && (
            <>
              {currentProgressPosition === 2 && (
                <EditKartePage
                  karteTemplates={karteTemplates}
                  questionTemplate={questionTemplate}
                  selectedTemplate={selectedTemplate}
                  onSelectTemplate={onSelectTemplate}
                  karte={bookOrder && bookOrder.karte}
                  disabled={isSubmitting}
                  error={mutationError}
                  onSubmit={onSubmitKarteForm}
                  onSave={onSave}
                  onAutoSave={onAutoSave}
                />
              )}
              {currentProgressPosition === 3 && (
                <OrderPaymentMethodPage onSubmit={onSelectPaymentMethod} onCancel={onBackToKartePage} />
              )}
              {currentProgressPosition === 4 && paymentMethod && (
                <OrderConfirmationPage
                  paymentType={paymentMethod.paymentMethodType}
                  couponCode={paymentMethod.couponCode}
                  user={user}
                  disabled={isSubmitting}
                  serverError={mutationError}
                  onSubmit={onSubmit}
                  onCancel={onBackToPaymentMethodPage}
                />
              )}
            </>
          )}
          <KarteTemplateRegistrationModal isOpen={modalIsOpen} templates={karteTemplates} onSubmit={onSubmitTemplate} />
        </UiStackTemplate>
      </Authenticated>
    </ProgressBarTemplate>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NewOrderContainer);
