import { parse } from 'query-string';
import React from 'react';
import { useNavigate, useLocation } from 'react-router';
import { Elements } from 'react-stripe-elements';

import useQuery from '../../hooks/common/useQuery';
import useCurrentUser from '../../hooks/store/useCurrentUser';
import Authenticated from '../../containers/common/Authenticated';
import CreditCardForm from '../payment/CreditCardForm';
import AppTemplate from '../templates/AppTemplate';
import GiftForm, { FormValues as GiftFormValues } from './GiftForm';
import GATracker from 'src/utils/GATracker';
import GiftDescription from './GiftDescription';
import GiftBottomNavigation from './GiftBottomNavigation';

const createBookCouponQuery = `mutation createBookCoupon(
  $shipmentType: String!,
  $recipientName: String,
  $email: String,
  $message: String
) {
  createBookCoupon(input: {
    shipmentType: $shipmentType,
    recipientName: $recipientName,
    email: $email,
    message: $message
  }) { success }
}`;

interface CreateBookCouponParams {
  shipmentType: 'email';
  recipientName: string;
  email: string;
  message: string | null;
}

interface CreateBookCouponResult {
  createBookCoupon: {
    success: boolean;
  };
}

const NewGiftPage: React.FC = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [currentUser] = useCurrentUser();
  const { isLoading, error, executeQuery } = useQuery<CreateBookCouponParams, CreateBookCouponResult>(
    createBookCouponQuery,
  );

  const onSubmit = async (values: GiftFormValues) => {
    await executeQuery({
      shipmentType: 'email',
      ...values,
    });

    navigate('/mypage/gifts/thanks', {
      state: {
        type: 'email',
        email: values.email,
      },
    });
    GATracker.trackEvent('send_gift');
  };

  const params = parse(search);
  const initialValues = {
    email: params.email as string,
  };
  const cardIsRegistered = !!currentUser && !!currentUser.cardLast4;
  const serverError = error ? error.message : null;

  return (
    <AppTemplate>
      <Authenticated>
        <GiftDescription title="メールで送る">
          <p>
            送りたい相手のメールアドレス宛に、
            <br />
            ギフトコードを記載したメールを送ります。
          </p>
        </GiftDescription>
        <GiftForm
          initialValues={initialValues}
          submittable={cardIsRegistered}
          disabled={isLoading}
          serverError={serverError}
          onSubmit={onSubmit}
        />
        {!cardIsRegistered && (
          <Elements locale="ja">
            <CreditCardForm />
          </Elements>
        )}
        <GiftBottomNavigation />
      </Authenticated>
    </AppTemplate>
  );
};

export default NewGiftPage;
