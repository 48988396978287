import omit from 'lodash-es/omit';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { injectStripe, ReactStripeElements } from 'react-stripe-elements';

import UserPhoto from 'src/components/users/UserPhoto';
import Input from 'src/components/atoms/form/Input';
import Alert from 'src/components/atoms/text/Alert';
import Button from 'src/components/atoms/button/Button';
import FileChangeButton from 'src/components/atoms/button/FileChangeButton';
import CreditCardRegistrationForm from 'src/components/auth/CreditCardRegistrationForm';
import { EmailRegexp, TelRegexp, PostalCodeRegexp, UserNameMaxLength, UserNameKanaMaxLength } from 'src/app.constants';
import FileLoader from 'src/utils/fileLoader';

import CSSModule from './UserProfileForm.module.scss';

export interface FormValues {
  name: string;
  nameKana: string;
  email: string;
  tel: string;
  postalCode: string;
  address: string;
  shippingPostalCode: string | null;
  shippingAddress: string | null;
  photoFilename?: string | null;
  stripeCardId?: string;
}

interface OwnProps {
  initialValues: Partial<FormValues>;
  initialPhoto: string | null;
  isLoading: boolean;
  isLoadingPhoto: boolean;
  updatingError: Error | null;
  onChangePhoto: (file: File | null) => Promise<string | void>;
  onSubmit: (values: FormValues) => void;
}

type Props = OwnProps & ReactStripeElements.InjectedStripeProps;

const UserProfileForm: React.FC<Props> = ({
  initialValues,
  initialPhoto,
  isLoading,
  isLoadingPhoto,
  updatingError,
  onChangePhoto,
  onSubmit,
  stripe,
  elements,
}) => {
  const [hasCardNumber, setHasCardNumber] = useState(false);
  const [isFetchingStripe, setIsFetchingStripe] = useState(false);
  const [stripeError, setStripeError] = useState<string>();
  const [photoDataUrl, setPhotoDataUrl] = useState<string | null>(null);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({ defaultValues: initialValues });

  const disabled = isFetchingStripe || isLoading;
  const cardNumElm = elements && elements.getElement('cardNumber');

  useEffect(() => {
    if (cardNumElm) {
      cardNumElm.on('change', response => {
        setHasCardNumber(!(response && response.empty));
      });
    }
  }, [cardNumElm]);

  const onPhotoChanged = async (file: File | null) => {
    const filename = await onChangePhoto(file);

    if (filename) {
      setValue('photoFilename', filename);

      if (file) {
        const dataUrl = await new FileLoader(file).readAsDataURL();
        setPhotoDataUrl(dataUrl);
      }
    } else {
      setValue('photoFilename', null);
      setPhotoDataUrl(null);
    }
  };

  const onSubmitForm = async (valuesWithStripeValues: FormValues) => {
    const values = omit(valuesWithStripeValues, 'cardBrand', 'cardExpYear', 'cardExpMonth', 'cardLast4') as FormValues;

    if (stripe && hasCardNumber) {
      setIsFetchingStripe(true);
      const { error: errorFromStripe, source } = await stripe.createSource({ type: 'card' });

      if (errorFromStripe) {
        setStripeError(errorFromStripe.message);
        setIsFetchingStripe(false);
        return;
      }

      if (source) {
        onSubmit({ ...values, stripeCardId: source.id });
        setIsFetchingStripe(false);
      }
    } else {
      onSubmit(values);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <div className={CSSModule.panelHeader}>
        <div className={CSSModule.userPhoto}>
          <UserPhoto src={photoDataUrl || initialPhoto || null} isLoading={isLoadingPhoto} />
        </div>
        <div className={CSSModule.fileChangeButton}>
          <FileChangeButton name="photo" onChange={onPhotoChanged}>
            変更
          </FileChangeButton>
        </div>
      </div>

      <div className="form-group mt-3">
        <Input
          {...register('name', {
            required: 'お名前をご入力ください。',
            maxLength: {
              value: UserNameMaxLength,
              message: `お名前は${UserNameMaxLength}文字以内でご入力ください。`,
            },
          })}
          label="お名前"
          required
        />
        {errors.name && <Alert message={errors.name.message} />}
      </div>
      <div className="form-group">
        <Input
          {...register('nameKana', {
            required: 'フリガナをご入力ください。',
            maxLength: {
              value: UserNameKanaMaxLength,
              message: `フリガナは${UserNameKanaMaxLength}文字以内でご入力ください。`,
            },
          })}
          label="フリガナ"
          required
        />
        {errors.nameKana && <Alert message={errors.nameKana.message} />}
      </div>
      <div className="form-group">
        <Input
          {...register('email', {
            required: 'メールアドレスをご入力ください。',
            pattern: {
              value: EmailRegexp,
              message: 'Eメールアドレスの形式が不正です。',
            },
          })}
          label="メールアドレス"
          required
        />
        {errors.email && <Alert message={errors.email.message} />}
      </div>
      <div className="form-group">
        <Input
          {...register('tel', {
            required: '電話番号をご入力ください。',
            pattern: { value: TelRegexp, message: '電話番号はハイフンなしでご入力ください。' },
          })}
          label="電話番号(ハイフンなしでご記入ください)"
          required
        />
        {errors.tel && <Alert message={errors.tel.message} />}
      </div>
      <div className="form-group">
        <Input
          {...register('postalCode', {
            required: '郵便番号をご入力ください。',
            pattern: { value: PostalCodeRegexp, message: '郵便番号はハイフンなしでご入力ください。' },
          })}
          label="郵便番号(ハイフンなしでご記入ください)"
          required
        />
        {errors.postalCode && <Alert message={errors.postalCode.message} />}
      </div>
      <div className="form-group">
        <Input {...register('address', { required: '住所をご入力ください。' })} label="住所" required />
        {errors.address && <Alert message={errors.address.message} />}
      </div>
      <div className="form-group">
        <Input
          {...register('shippingPostalCode', {
            pattern: { value: PostalCodeRegexp, message: '郵便番号はハイフンなしでご入力ください。' },
          })}
          label="お届け先郵便番号"
        />
        {errors.shippingPostalCode && <Alert message={errors.shippingPostalCode.message} />}
      </div>
      <div className="form-group mb-5">
        <Input {...register('shippingAddress')} label="配送先住所" />
        {errors.shippingAddress && <Alert message={errors.shippingAddress.message} />}
      </div>
      <div className="mb-5">
        <CreditCardRegistrationForm error={stripeError} />
      </div>
      {updatingError && <Alert error={updatingError} />}
      <Button disabled={disabled}>送信</Button>
    </form>
  );
};

export default injectStripe(UserProfileForm);
