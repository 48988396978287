import React from 'react';
import { CardNumberElement, CardCvcElement, CardExpiryElement } from 'react-stripe-elements';

import Alert from '../atoms/text/Alert';
import CreditCardBrands from '../../assets/auth/credit-card-brands.png';

import CSSModule from './CreditCardRegistrationForm.module.scss';

const stripeElementStyle = {
  base: {
    fontSize: '16px',
  },
};

interface Props {
  error?: string;
}

const CreditCardRegistrationForm: React.FC<Props> = ({ error }) => (
  <>
    <h3 className={CSSModule.title}>お支払い方法</h3>
    <div className={CSSModule.brandsWrapper}>
      <div className={CSSModule.brandsDescription}>
        以下のクレジットカードを
        <br />
        ご利用いただけます。
      </div>
      <div className={CSSModule.brandsLogos}>
        <img src={CreditCardBrands} alt="クレジットカード" width="100%" />
      </div>
    </div>
    <div className="mt-4 row">
      <div className="col-12">
        <div className={CSSModule.label}>カード番号</div>
        <div className={CSSModule.stripeElement}>
          <CardNumberElement style={stripeElementStyle} />
        </div>
      </div>
      <div className="col-6">
        <div className={CSSModule.label}>有効期限</div>
        <div className={CSSModule.stripeElement}>
          <CardExpiryElement style={stripeElementStyle} />
        </div>
      </div>
      <div className="col-6">
        <div className={CSSModule.label}>CVC</div>
        <div className={CSSModule.stripeElement}>
          <CardCvcElement style={stripeElementStyle} />
        </div>
      </div>
    </div>
    {error && <Alert message={error} />}
  </>
);

export default CreditCardRegistrationForm;
