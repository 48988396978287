import React, { useState } from 'react';
import useCurrentUser from '../../hooks/store/useCurrentUser';

import PaymentMethodSelect, { PaymentMethodType } from './PaymentMethodSelect';
import Button from '../atoms/button/Button';
import { CreditCard } from '../../interfaces/user';

import CSSModule from './OrderPaymentMethodPage.module.scss';

export interface PaymentMethod {
  paymentMethodType: PaymentMethodType;
  couponCode: string | null;
}

interface Props {
  onSubmit: (paymentMethod: PaymentMethod) => void;
  onCancel: () => void;
}

const OrderPaymentMethodPage: React.FC<Props> = ({ onSubmit, onCancel }) => {
  const [paymentMethodType, setPaymentMethodType] = useState<PaymentMethodType | null>(null);
  const [couponCode, setCouponCode] = useState<string>('');
  const [currentUser, setCurrentUser] = useCurrentUser();

  const onSelectPaymentMethodType = (type: PaymentMethodType) => {
    setPaymentMethodType(type);
  };

  const goNextPage = () => {
    if (paymentMethodType) {
      onSubmit({
        paymentMethodType,
        couponCode,
      });
    }
  };

  const onRegisterCard = (card: CreditCard) => {
    setCurrentUser(
      user =>
        user && {
          ...user,
          cardBrand: card.brand,
          cardLast4: card.last4,
          cardExpYear: card.expYear,
          cardExpMonth: card.expMonth,
        },
    );
  };

  const cardIsRegistered = !!(currentUser && currentUser.cardLast4);
  const submittable =
    (paymentMethodType === 'card' && cardIsRegistered) || (paymentMethodType === 'coupon' && !!couponCode);

  return (
    <div className={CSSModule.OrderPaymentMethodPage}>
      <PaymentMethodSelect
        selectedPaymentMethodType={paymentMethodType}
        onSelectPaymentMethodType={onSelectPaymentMethodType}
        couponCode={couponCode}
        onChangeCouponCode={setCouponCode}
        creditCard={{
          brand: currentUser ? currentUser.cardBrand : null,
          last4: currentUser ? currentUser.cardLast4 : null,
          expMonth: currentUser ? currentUser.cardExpMonth : null,
          expYear: currentUser ? currentUser.cardExpYear : null,
        }}
        onRegisterCard={onRegisterCard}
      />
      <div className={CSSModule.OrderPaymentMethodPage__Button}>
        <Button color="primary" disabled={!submittable} onClick={goNextPage}>
          次へ
        </Button>
      </div>
      <div className={CSSModule.OrderPaymentMethodPage__Button}>
        <Button color="default" onClick={onCancel}>
          戻る
        </Button>
      </div>
    </div>
  );
};

export default OrderPaymentMethodPage;
