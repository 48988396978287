import React from 'react';
import { useNavigate } from 'react-router';
import { Elements } from 'react-stripe-elements';

import Authenticated from 'src/containers/common/Authenticated';
import useCurrentUser from 'src/hooks/store/useCurrentUser';
import useQuery from 'src/hooks/common/useQuery';
import GATracker from 'src/utils/GATracker';
import AppTemplate from '../templates/AppTemplate';
import GiftDescription from './GiftDescription';
import CreditCardForm from '../payment/CreditCardForm';
import GiftBottomNavigation from './GiftBottomNavigation';
import ByHandsGiftForm from './ByHandsGiftForm';

const createBookCouponQuery = `mutation createBookCoupon(
  $shipmentType: String!,
  $postalCode: String!,
  $address: String!
) {
  createBookCoupon(input: {
    shipmentType: $shipmentType,
    postalCode: $postalCode,
    address: $address
  }) { success }
}`;

interface CreateBookCouponParams {
  shipmentType: 'self';
  postalCode: string;
  address: string;
}

interface CreateBookCouponResult {
  createBookCoupon: {
    success: boolean;
  };
}

const GiftByHandsPage: React.FC = () => {
  const [currentUser] = useCurrentUser();
  const navigate = useNavigate();

  const { isLoading, error, executeQuery } = useQuery<CreateBookCouponParams, CreateBookCouponResult>(
    createBookCouponQuery,
  );

  const cardIsRegistered = !!currentUser && !!currentUser.cardLast4;
  const serverError = error ? error.message : null;

  const onSubmit = async () => {
    if (!currentUser) {
      return;
    }

    const postalCode = currentUser.shippingPostalCode || currentUser.postalCode;
    const address = currentUser.shippingAddress || currentUser.address;

    await executeQuery({ shipmentType: 'self', postalCode, address });

    navigate('/mypage/gifts/thanks', {
      state: { type: 'self', postalCode, address },
    });
    GATracker.trackEvent('send_gift_by_hand');
  };

  return (
    <AppTemplate>
      <Authenticated>
        <GiftDescription title="直接手渡しする">
          <p>
            ギフトコードが印刷されたカードを
            <br />
            送り主のご住所へ郵送します。
            <br />
            受け取り後、相手にカードを直接手渡しできます。
          </p>
        </GiftDescription>
        {currentUser && (
          <ByHandsGiftForm
            postalCode={currentUser.shippingPostalCode || currentUser.postalCode}
            address={currentUser.shippingAddress || currentUser.address}
            submittable={cardIsRegistered}
            disabled={isLoading}
            serverError={serverError}
            onSubmit={onSubmit}
          />
        )}
        {!cardIsRegistered && (
          <Elements locale="ja">
            <CreditCardForm />
          </Elements>
        )}
        <GiftBottomNavigation />
      </Authenticated>
    </AppTemplate>
  );
};

export default GiftByHandsPage;
