import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { StripeProvider } from 'react-stripe-elements';

import store from './reducers';
import Routes from './routes';
import Authentication from './containers/common/Authentication';
import FlashMessageContainer from './containers/common/FlashMessageContainer';
import { StripeApiKey } from './app.constants';
import Analytics from './routes/Analytics';
import ScrollToTop from './routes/ScrollToTop';

import './styles/global.scss';

const App: React.FC = () => (
  <Provider store={store}>
    <StripeProvider apiKey={StripeApiKey}>
      <Authentication>
        <BrowserRouter>
          <Routes />
          <Analytics />
          <ScrollToTop />
        </BrowserRouter>
      </Authentication>
    </StripeProvider>
    <FlashMessageContainer />
  </Provider>
);

export default App;
