import React from 'react';
import { useNavigate } from 'react-router';
import { Elements } from 'react-stripe-elements';

import Authenticated from 'src/containers/common/Authenticated';
import useCurrentUser from 'src/hooks/store/useCurrentUser';
import useQuery from 'src/hooks/common/useQuery';
import GATracker from 'src/utils/GATracker';
import GiftDescription from './GiftDescription';
import GiftBottomNavigation from './GiftBottomNavigation';
import PostCardGiftForm, { FormValues } from './PostCardGiftForm';
import AppTemplate from '../templates/AppTemplate';
import CreditCardForm from '../payment/CreditCardForm';

const createBookCouponQuery = `mutation createBookCoupon(
  $shipmentType: String!,
  $recipientName: String!,
  $postalCode: String!,
  $address: String!
) {
  createBookCoupon(input: {
    shipmentType: $shipmentType,
    recipientName: $recipientName,
    postalCode: $postalCode,
    address: $address
  }) { success }
}`;

interface CreateBookCouponParams {
  shipmentType: 'other';
  recipientName: string;
  postalCode: string;
  address: string;
}

interface CreateBookCouponResult {
  createBookCoupon: {
    success: boolean;
  };
}

const GiftByPostCardPage: React.FC = () => {
  const [currentUser] = useCurrentUser();
  const navigate = useNavigate();

  const { isLoading, error, executeQuery } = useQuery<CreateBookCouponParams, CreateBookCouponResult>(
    createBookCouponQuery,
  );

  const cardIsRegistered = !!currentUser && !!currentUser.cardLast4;
  const serverError = error ? error.message : null;

  const onSubmit = async (values: FormValues) => {
    await executeQuery({
      shipmentType: 'other',
      recipientName: values.recipientName,
      postalCode: values.recipientPostalCode,
      address: values.recipientAddress,
    });

    navigate('/mypage/gifts/thanks', {
      state: {
        type: 'other',
        recipientName: values.recipientName,
        postalCode: values.recipientPostalCode,
        address: values.recipientAddress,
      },
    });
    GATracker.trackEvent('send_gift_by_post');
  };

  return (
    <AppTemplate>
      <Authenticated>
        <GiftDescription title="郵送する">
          <p>
            ギフトコードが印刷されたカードを
            <br />
            送りたい相手のご住所に郵送します。
          </p>
        </GiftDescription>
        <PostCardGiftForm
          submittable={cardIsRegistered}
          disabled={isLoading}
          serverError={serverError}
          onSubmit={onSubmit}
        />
        {!cardIsRegistered && (
          <Elements locale="ja">
            <CreditCardForm />
          </Elements>
        )}
        <GiftBottomNavigation />
      </Authenticated>
    </AppTemplate>
  );
};

export default GiftByPostCardPage;
