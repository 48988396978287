import React, { useEffect, useState } from 'react';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe,
  ReactStripeElements,
} from 'react-stripe-elements';

import { generateColumnQuery } from '../../actions/queries';
import { meColumns } from '../../actions/queries/authQueries';
import useQuery from '../../hooks/common/useQuery';
import { CreditCard, User } from '../../interfaces/user';
import Button from '../atoms/button/Button';
import Alert from '../atoms/text/Alert';
import MainLogo from '../../assets/auth/credit-card-brands.png';
import useCurrentUser from '../../hooks/store/useCurrentUser';

import CSSModule from './CreditCardForm.module.scss';

interface OwnProps {
  onCompleted?: (card: CreditCard) => void;
}

type Props = OwnProps & ReactStripeElements.InjectedStripeProps;

const stripeElementStyle = {
  base: {
    fontSize: '16px',
  },
};

const UpdateUserQuery = `mutation updateUser(
  $user: UserInput!
) {
  updateUser(input: { user: $user }) {
    user { ${generateColumnQuery(meColumns)} }
  }
}`;

interface UpdateUserParams {
  user: {
    stripeCardId: string;
    agreeTerms: true;
  };
}

interface UpdateUserResult {
  updateUser: {
    user: User;
  };
}

const CreditCardForm: React.FC<Props> = ({ stripe, onCompleted }) => {
  const [, setCurrentUser] = useCurrentUser();
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const {
    isLoading,
    executeQuery,
    result: updateUserResult,
  } = useQuery<UpdateUserParams, UpdateUserResult>(UpdateUserQuery);

  useEffect(() => {
    if (updateUserResult?.updateUser.user) {
      setCurrentUser(updateUserResult.updateUser.user);
    }
  }, [setCurrentUser, updateUserResult]);

  const onSubmit = async () => {
    if (stripe) {
      setIsFetching(true);
      setError(null);

      const { error: stripeError, token } = await stripe.createToken({ type: 'card' });

      if (token && token.card) {
        try {
          await executeQuery({ user: { stripeCardId: token.id, agreeTerms: true } });

          if (onCompleted) {
            onCompleted({
              brand: token.card.brand,
              last4: token.card.last4,
              expYear: token.card.exp_year,
              expMonth: token.card.exp_month,
            });
          }
        } catch {
          setError('予期しないエラーが発生しました。');
        }
      }

      if (stripeError) {
        setError(stripeError.message || null);
      }

      setIsFetching(false);
    }
  };

  const disabled = isFetching || isLoading;

  return (
    <div className={CSSModule.CreditCardForm}>
      <div className={CSSModule.CreditCardForm__Description}>
        <div className={CSSModule.CreditCardForm__TextDescription}>
          以下のクレジットカードを
          <br />
          ご利用いただけます。
        </div>
        <img src={MainLogo} alt="クレジットカードブランドロゴ" className={CSSModule.CreditCardForm__LogoImage} />
      </div>
      <label className={CSSModule.CreditCardForm__Label}>カード番号</label>
      <div className={CSSModule.CreditCardForm__StripeField}>
        <CardNumberElement style={stripeElementStyle} />
      </div>
      <label className={CSSModule.CreditCardForm__Label}>有効期限</label>
      <div className={CSSModule.CreditCardForm__StripeField}>
        <CardExpiryElement style={stripeElementStyle} />
      </div>
      <label className={CSSModule.CreditCardForm__Label}>CVC</label>
      <div className={CSSModule.CreditCardForm__StripeField}>
        <CardCvcElement style={stripeElementStyle} />
      </div>
      {error && <Alert message={error} />}
      <div className={CSSModule.CreditCardForm__ButtonField}>
        <Button size="small" disabled={disabled} onClick={onSubmit}>
          カードを登録
        </Button>
      </div>
    </div>
  );
};

export default injectStripe(CreditCardForm);
