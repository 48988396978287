import React from 'react';
import { ResolveThunks, connect } from 'react-redux';

import { RootState } from '../../reducers';
import { register } from '../../actions/auth';
import { setFlashMessage as setFlashMessageAction } from '../../actions/common';
import SignUpForm, { FormValues } from '../../components/auth/SignUpForm';
import { Elements } from 'react-stripe-elements';

const mapStateToProps = (state: RootState) => ({
  isLoading: state.auth.isRegistering,
  error: state.auth.registeringError,
});

const mapDispatchToProps = {
  signUp: register.action,
  setFlashMessage: setFlashMessageAction,
};

type Props = ReturnType<typeof mapStateToProps> & ResolveThunks<typeof mapDispatchToProps>;

const SignUpContainer: React.FC<Props> = ({ signUp, setFlashMessage, isLoading, error }) => {
  const onSubmit = async (user: FormValues) => {
    await signUp({ user });
    setFlashMessage({ message: 'ユーザー登録が完了しました。' });
  };

  return (
    <Elements locale="ja">
      <SignUpForm onSubmit={onSubmit} disabled={isLoading} error={error} />
    </Elements>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpContainer);
