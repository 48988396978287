import React from 'react';
import { Link } from 'react-router-dom';

import { User } from '../../interfaces/user';
import UserPhoto from '../users/UserPhoto';
import TextWithEllipsis from '../atoms/text/TextWithEllipsis';

import CSSModule from './ProfileDetail.module.scss';

interface Props {
  user: User;
}

const ProfileDetail: React.FC<Props> = ({ user }) => (
  <div className={CSSModule.profileDetail}>
    <div className={CSSModule.panelHeader}>
      <div className={CSSModule.userPhoto}>
        <UserPhoto src={user.photo} />
      </div>
      <h3 className={CSSModule.userName}>
        <TextWithEllipsis text={`${user.name}さん`} />
      </h3>
      <Link to="/mypage/profile/edit" className={CSSModule.editLink}>
        編集
      </Link>
    </div>
    <div className={CSSModule.profileContent}>
      <dl>
        <dt className={CSSModule.label}>お名前</dt>
        <dd className={CSSModule.value}>{user.name}</dd>
        <dt className={CSSModule.label}>フリガナ</dt>
        <dd className={CSSModule.value}>{user.nameKana}</dd>
        <dt className={CSSModule.label}>メールアドレス</dt>
        <dd className={CSSModule.value}>{user.email}</dd>
        <dt className={CSSModule.label}>電話番号</dt>
        <dd className={CSSModule.value}>{user.tel}</dd>
        <dt className={CSSModule.label}>郵便番号</dt>
        <dd className={CSSModule.value}>{user.postalCode}</dd>
        <dt className={CSSModule.label}>住所</dt>
        <dd className={CSSModule.value}>{user.address}</dd>
        {user.shippingPostalCode && (
          <>
            <dt className={CSSModule.label}>お届け先郵便番号</dt>
            <dd className={CSSModule.value}>{user.shippingPostalCode}</dd>
          </>
        )}
        {user.shippingAddress && (
          <>
            <dt className={CSSModule.label}>お届け先住所</dt>
            <dd className={CSSModule.value}>{user.shippingAddress}</dd>
          </>
        )}
        <dt className={CSSModule.label}>クレジットカード</dt>
        <dd className={CSSModule.value}>
          {user.cardLast4 ? (
            <>
              {user.cardBrand} **** **** **** {user.cardLast4}
              <br />
              有効期限: {user.cardExpMonth}/{user.cardExpYear}
            </>
          ) : (
            '未登録'
          )}
        </dd>
      </dl>
    </div>
  </div>
);

export default ProfileDetail;
