import React from 'react';
import { Route, Routes } from 'react-router';

import WelcomePageContainer from 'src/containers/welcome/WelcomePageContainer';
import LoginPageContainer from 'src/containers/auth/LoginPageContainer';
import ForgotPasswordContainer from 'src/containers/auth/ForgotPasswordContainer';
import ResetPasswordContainer from 'src/containers/auth/ResetPasswordContainer';
import StaffsContainer from 'src/containers/staffs/StaffsContainer';
import NewOrderContainer from 'src/containers/orders/NewOrderContainer';
import OrderThanksContainer from 'src/containers/orders/OrderThanksContainer';
import KarteDetailContainer from 'src/containers/kartes/KarteDetailContainer';
import MyPageContainer from 'src/containers/mypage/MyPageContainer';
import ProfileMenuListContainer from 'src/containers/mypage/ProfileMenuListContainer';
import ProfileDetailContainer from 'src/containers/mypage/ProfileDetailContainer';
import EditProfileContainer from 'src/containers/mypage/EditProfileContainer';
import KarteTemplatesContainer from 'src/containers/karteTemplates/KarteTemplatesContainer';
import AdminContainer from 'src/containers/admin/AdminContainer';
import NotFoundPage from 'src/components/common/NotFoundPage';
import GiftsPage from 'src/components/gifts/GiftsPage';
import GiftByPostCardPage from 'src/components/gifts/GiftByPostCardPage';
import GiftByHandsPage from 'src/components/gifts/GiftByHandsPage';
import NewGiftPage from 'src/components/gifts/NewGiftPage';
import GiftRequestPage from 'src/components/gifts/GiftRequestPage';
import GiftThanksPage from 'src/components/gifts/ThanksPage';
import TermsPage from 'src/components/terms/TermsPage';

const Router: React.FC = () => (
  <Routes>
    <Route path="/" Component={WelcomePageContainer} />
    <Route path="/login" Component={LoginPageContainer} />
    <Route path="/forgot-password" Component={ForgotPasswordContainer} />
    <Route path="/reset-password" Component={ResetPasswordContainer} />
    <Route path="/staffs/:id/orders/new" Component={NewOrderContainer} />
    <Route path="/staffs/:id/orders/thanks" Component={OrderThanksContainer} />
    <Route path="/staffs/*" Component={StaffsContainer} />
    <Route path="/gifts" Component={GiftsPage} />
    <Route path="/orders/:bookOrderId/karte" Component={KarteDetailContainer} />
    <Route path="/mypage" Component={MyPageContainer} />
    <Route path="/mypage/profile" Component={ProfileDetailContainer} />
    <Route path="/mypage/profile/menu" Component={ProfileMenuListContainer} />
    <Route path="/mypage/profile/edit" Component={EditProfileContainer} />
    <Route path="/mypage/karte-templates/*" Component={KarteTemplatesContainer} />
    <Route path="/mypage/gifts/new" Component={NewGiftPage} />
    <Route path="/mypage/gifts/post-card" Component={GiftByPostCardPage} />
    <Route path="/mypage/gifts/by-hands" Component={GiftByHandsPage} />
    <Route path="/mypage/gifts/request" Component={GiftRequestPage} />
    <Route path="/mypage/gifts/thanks" Component={GiftThanksPage} />
    <Route path="/terms" Component={TermsPage} />
    <Route path="/admin/*" Component={AdminContainer} />
    <Route Component={NotFoundPage} />
  </Routes>
);

export default Router;
